import React from "react"
import Layout from "../../components/layout/t1/layout"
import CombineProcess from "../../components/how-it-works/combine-process"

const CombineProcessPage = () => {
  return (
    <Layout>
      <CombineProcess />
    </Layout>
  )
}

export default CombineProcessPage

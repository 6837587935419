import React from "react"
import { Link } from "gatsby"
import { BsArrowRight } from "react-icons/bs"
import "./index.scss"
import { useStaticQuery, graphql } from "gatsby"

const CardItem = props => {
  const { title, subTitle, toPage, cardImage, _cardInfo, titleBtn } = props

  let cardItem = {}
  try {
    const result = useStaticQuery(graphql`
      {
        mdx(fileAbsolutePath: { regex: "/(how-cards)/" }) {
          frontmatter {
            titleButton
          }
        }
      }
    `)
    cardItem = result.mdx.frontmatter
  } catch (error) {
    cardItem = _cardInfo
  }
  return (
    <div className="howCards p-3 p-md-5" data-testid="CardItem">
      <Link to={toPage} className="link">
        <div className="titleContainer  row">
          <div className="title col-9">
            <h2 className="titleStyle">{title}</h2>
          </div>

          <div className="image col-3 ">
            <img
              alt={`maxapps maximo: ${title}`}
              title={`maxapps maximo: ${title}`}
              src={cardImage}
              className="imageStyle p-0"
              data-testid="image"
            />
          </div>
        </div>

        <div className="pargContainer ">
          <div className="col-12 col-sm-9 col-md-9">
            <p
              className="description"
              dangerouslySetInnerHTML={{ __html: subTitle }}
            ></p>
          </div>
        </div>
        <div className="btnContainer mt-3 py-2 ">
          <div className="">
            <button type="button" className="btn">
              {titleBtn ? titleBtn : cardItem.titleButton}
              <BsArrowRight className="how-card-arrow" size={20} />
            </button>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default CardItem

import React from "react"

import "./index.scss"

const DescriptionItem = () => {
  return (
    <div className=" CombineProcessCards p-5">
      <div className="row">
        <div className="col-12  ">
          <p className="descriptionMain">
            Build your processes from your <strong>IBM Maximo</strong> with{" "}
            <strong>MAXapps</strong>, a single point of access for your{" "}
            <strong>Maximo team</strong>. This allows you to design your process
            on a <strong>standard Maximo desktop</strong> and on mobile from a
            single point. You can have a technician starting a workflow in the
            field, a scheduler planning the work on his office, and the external
            supplier services that are needed. Forget the big implementation
            project, just design your process with the business and do it with{" "}
            <strong>MAXapps</strong>.
          </p>
        </div>
      </div>
    </div>
  )
}

export default DescriptionItem

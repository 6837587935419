import React from "react"
import CardItem from "../../how-cards/card-item"
import DescriptionItem from "./description-item"
import { useStaticQuery, graphql, Link } from "gatsby"
import YellowButton from "../../../home/buttons/yellow-button"
import { BsArrowRight } from "react-icons/bs"
import "./index.scss"

const CombineProcessCards = ({ _detailMock, _Card }) => {
  let cardInfo = []
  let info = {}
  try {
    const data = useStaticQuery(graphql`
      {
        mdx(fileAbsolutePath: { regex: "/(combine-process)/" }) {
          exports {
            Detail {
              cardImage
              subTitle
              title
              toPage
            }
          }
          frontmatter {
            titleBottom1
            titleBottom2
            titleButton
            sizeButton
            titleMain
            subTitleMain
            subTitleDetail1
            subTitleDetail2
            titleDetail
          }
        }
      }
    `)

    cardInfo = data.mdx.exports.Detail
    info = data.mdx.frontmatter
  } catch (error) {
    info = _detailMock
    cardInfo = _Card
  }
  return (
    <div
      id="CombineProcessCards"
      className="CombineProcessCards"
      data-testid="CombineProcessCards"
    >
      <div className=" px-5 py-5">
        <h1 className="title">{info?.titleDetail}</h1>
        <p className="subTitleDetail">
          <span
            dangerouslySetInnerHTML={{
              __html: info.subTitleDetail1,
            }}
          ></span>
          <br />
          <span
            dangerouslySetInnerHTML={{
              __html: info.subTitleDetail2,
            }}
          ></span>
        </p>
      </div>

      <div id="howCards" className="row ">
        <div className="col-12 col-lg-6 p-0 pt-3">
          <DescriptionItem />
        </div>

        {cardInfo.map((item, index) => {
          return (
            <div
              className="col-12 col-lg-6 p-0"
              key={index}
              data-testid={index}
            >
              <Link to={item.toPage} className="link">
                <CardItem {...item} />
              </Link>
            </div>
          )
        })}
      </div>
      <div className="row justify-content-center  py-5 my-5">
        <span className="centeredText ">
          {info?.titleBottom1}
          <br />
          {info?.titleBottom2}
        </span>
        <div className="btn-combine-bottom mt-3 py-2" data-testid="Button">
          <YellowButton
            data={info?.titleButton}
            size={info?.sizeButton}
            children={
              <BsArrowRight size={20} className="yellow-btn-arrow ms-2" />
            }
            link="/docs/Tutorials/"
          />
        </div>
      </div>
    </div>
  )
}

export default CombineProcessCards

import React from "react"
import Seo from "../../seo"
import CombineProcessCards from "./combine-process-cards"

const CombineProcess = () => {
  return (
    <div>
      <Seo
        title="Combine processes and data"
        description="Combine processes and data. Any process, any mboset, any role. You bring the process,and MAXapps will bring it to the end-user."
      />
      <CombineProcessCards />
    </div>
  )
}

export default CombineProcess
